import React from "react"
import Opener from "../pre-opener"
import {Helmet} from "react-helmet"

export default () =>
  <>
    <Helmet>
      <meta property="og:url" content="https://moondisaster.org/"/>
      <meta property="og:title" content="In Event of Moon Disaster - Home"/>
      <meta property="og:description" content="What if the moon landing had gone wrong? In Event of Moon Disaster presents an alternative history using deepfake tech showing its potential for misinformation."/>
      <meta property="og:image:secure_url" content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"/>
      <meta property="og:image:url" content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"/>
      <meta property="og:image" content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"/>
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      <meta property="og:image:alt" content="lunar landscape with project logo"/>
      <meta property="article:author:name" content="Francesca Panetta and Halsey Burgund"/>
      <meta name="twitter:title" content="In Event of Moon Disaster - Home"/>
      <meta name="twitter:description" content="What if the moon landing had gone wrong? In Event of Moon Disaster presents an alternative history using deepfake tech showing its potential for misinformation."/>
      <meta name="twitter:creator" content="Francesca Panetta and Halsey Burgund"/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:image" content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x854.jpg?p=1806"/>
      <meta name="twitter:image:alt" content="lunar landscape with project logo"/>
      <meta itemProp="name" content="In Event of Moon Disaster - Home"/>
      <meta itemProp="description" content="What if the moon landing had gone wrong? In Event of Moon Disaster presents an alternative history using deepfake tech showing its potential for misinformation."/>
      <meta name="author" content="Francesca Panetta and Halsey Burgund"/>
      <meta name="description" content="What if the moon landing had gone wrong? In Event of Moon Disaster presents an alternative history using deepfake tech showing its potential for misinformation."/>
      <meta name="thumbnail" content="https://moondisaster.space/wp-content/uploads/2020/07/ieomdPromoNoDate-1200x630-cropped.jpg"/>
    </Helmet>
    <Opener />
  </>
