import React, { useState } from "react"
import Layout from "./layout"
import { motion } from "framer-motion"
import styled from "@emotion/styled"
import { CrtPage } from "./question"
import { SmallRoundButton } from "./button"
import GlitchBackground from "./glitch-background"
import { navigate } from "gatsby"
import theme from "./theme"

const initialDelay = 0.5

/// Stages of text to show at the start of the experience.
const BigText = styled.p({
  fontSize: "5rem",
  margin: "0 0 4rem 0",
  [theme.mq.desktophd]: {
    fontSize: "5rem",
  },
  [theme.mq.desktop]: {
    fontSize: "5rem",
  },
  [theme.mq.tablet]: {
    fontSize: "4rem",
  },
  [theme.mq.mobile]: {
    fontSize: "3rem",
    margin: "0 0 2rem 0",
  },
})

const BlockText = styled.div({
  fontFamily: theme.fonts.header,
  lineHeight: "1.7em",
  maxWidth: "40rem",
  p: {
    lineHeight: "3.0rem",
    [theme.mq.desktop]: {
      lineHeight: "2.5rem",
    },
    [theme.mq.tablet]: {
      lineHeight: "2.3rem",
    },
    [theme.mq.mobile]: {
      margin: "1rem 0 1rem 0",
      lineHeight: "1.5rem",
    },
  },
  [theme.mq.desktophd]: {},
  [theme.mq.desktop]: {
    maxWidth: "34rem",
  },
  [theme.mq.tablet]: {
    maxWidth: "28rem",
  },
  [theme.mq.mobile]: {
    maxWidth: "24rem",
  },
})

const ItalicText = styled.div({
  fontFamily: theme.fonts.emphasis,
  fontStyle: "italic",
  letterSpacing: "0.5rem",
  fontSize: "5rem",
  textAlign: "right",
  position: "absolute",
  bottom: "10%",
  left: "8rem",
  [theme.mq.desktophd]: {
    bottom: "15%",
    fontSize: "6rem",
  },
  [theme.mq.desktop]: {
    fontSize: "6rem",
  },
  [theme.mq.tablet]: {
    fontSize: "5rem",
  },
  [theme.mq.mobile]: {
    left: "1.5rem",
    fontSize: "4rem",
    lineHeight: "3.5rem",
  },
})

// accumulation text approach
// const textStages = [
//   <BigText>In 1969,</BigText>,
//   <BlockText>
//     <p>the world gathered to watch the moon landing.</p>
//     <p>It was risky, so a backup speech was written in case the mission failed.</p>
//     <p>Thankfully it was never delivered.</p>
//   </BlockText>,
//   <ItalicText>Until Now...</ItalicText>,
// ];

// text all at once
const textStages = [
  <>
    <BigText>
      In 1969,
      <br />
    </BigText>
    <BlockText>
      <p>the world gathered to watch the moon landing.</p>
      <p>
        It was extraordinarily risky, so a backup speech was written in case the
        mission failed.
      </p>
      <p>Thankfully it was never delivered.</p>
    </BlockText>
    <ItalicText>Until Now...</ItalicText>
  </>,
]

// TODO pull out CRT overlay into its own component for reuse.
const Opener = () => {
  const [stage, setStage] = useState(0)

  return (
    <Layout alternateMenu={true} menuHidden={true}>
      <CrtPage
        initial={{ width: 0, height: 2 }}
        animate={{ width: "100vw", height: "100vh" }}
        exit="undefined"
        transition={{
          delay: initialDelay,
          height: { delay: initialDelay + 0.2, duration: 0.2, ease: "easeIn" },
          width: { delay: initialDelay, duration: 0.2 },
        }}
        onClick={() => {
          if (stage >= textStages.length - 1) {
            // Route to the question page when we're finished here.
            navigate("/film#question")
          } else {
            // Progress the text on the screen.
            setStage(stage + 1)
          }
        }}
      >
        {/* <Background /> */}
        <GlitchBackground />
        <CentralText>
          {textStages.map((e, idx) => (
            <StagedText key={idx} visible={idx <= stage}>
              {e}
            </StagedText>
          ))}
        </CentralText>
        <Continue>
          <SmallRoundButton to="/film#question">Tap to Continue</SmallRoundButton>
        </Continue>
      </CrtPage>
    </Layout>
  )
}
export default Opener

const BottomText = styled.div({
  position: "absolute",
  bottom: 15,
  color: "#aaaaaa",
})

const Continue = styled.div({
  position: "absolute",
  bottom: "4rem",
  right: "4rem",
  [theme.mq.mobile]: {
    right: "2rem",
    bottom: "4.5rem",
  },
})

const StagedText = styled(motion.div)(
  {
    transition: "opacity 1s",
  },
  (props: { visible: boolean }) => ({
    opacity: props.visible ? 1 : 0,
    visibility: props.visible ? "visible" : "hidden",
    margin: "5rem 0 0 0",
  })
)

const Background = styled((props: { className?: string }) => (
  <video autoPlay loop muted className={props.className}>
    <source
      src="https://drive.google.com/uc?export=download&id=1v96dIK7GrE8f7mThq3ZWWmjw9WEliev-"
      type="video/mp4"
    />
  </video>
))({
  position: "absolute",
  zIndex: -1,
  minWidth: "100vw",
  minHeight: "100vh",
  width: "auto",
  height: "auto",
})

const CentralText = styled(motion.div)({
  height: "100%",
  width: "100%",
  textAlign: "left",
  textTransform: "uppercase",
  fontSize: "2rem",
  lineHeight: "2rem",
  position: "relative",
  padding: "5rem 2rem 0 8rem",
  fontFamily: theme.fonts.special,
  // background: "#00000080",
  [theme.mq.desktophd]: {
    margin: "0 50px",
    // fontSize: "1.5rem",
  },
  [theme.mq.desktop]: {
    margin: "0 50px",
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    padding: "5rem 2rem 0 8rem",
  },
  [theme.mq.tablet]: {
    margin: "0 50px",
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    padding: "3rem 2rem 0 8rem",
  },
  [theme.mq.mobile]: {
    margin: "0 50px",
    fontSize: "1.4rem",
    lineHeight: "1.2rem",
    padding: "3rem 1.5rem 0 1.5rem",
  },
})
