import React, { useEffect, useState } from "react"
import Layout from "./layout"
import { motion } from "framer-motion"
import styled from "@emotion/styled"
import { CrtPage } from "./question"
import { RoundButton } from "./button"
import GlitchBackground from "./glitch-background"
import { navigate } from "gatsby"
import theme from "./theme"
import logo from "./assets/mit-cav.png"
import Opener from "./opener"
import { AnimatePresence } from "framer-motion"

export default () => {
  const [done, setDone] = useState(false)
  useEffect(() => {
    setTimeout(() => setDone(true), 3500)
  }, [])

  if (done) {
    return <Opener />
  } else {
    return (
      <Layout menuHidden={true} alternateMenu={true}>
        <CrtPage
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1, 0] }}
          transition={{ delay: 0.5, duration: 2, ease: "easeOut" }}
        >
          <CavLogoImg src={logo} />
        </CrtPage>
      </Layout>
    )
  }
}

const CavLogoImg = styled.img({
  width: "500px",
  position: "absolute",
  top: "40vh",
  [theme.mq.mobile]: {
    width: "70vw",
  },
})
